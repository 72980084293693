<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title" v-if="type === 'edit'">
                <p>序号：{{xuhao}}</p>
            </div>
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                <div class="content">
                    <div class="con-item">
                        <a-form-item label="工号">
                            <a-input v-model:value="gonghao" placeholder="请输入工号"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="姓名">
                            <a-input v-model:value="xingming" placeholder="请输入姓名"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="证照类型">
                            <a-input v-model:value="zhengzhaoleixing" placeholder="请输入证照类型"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="证照号码">
                            <a-input v-model:value="zhengzhaohaoma" placeholder="请输入证照号码"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="申报表">
                            <a-input v-model:value="shenbaobiao" placeholder="请输入申报表"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="所得项目">
                            <a-input v-model:value="suodexiangmu" placeholder="请输入所得项目"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="所得期间起">
                            <a-input v-model:value="suodeqijianqi" placeholder="请输入所得期间起"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="所得期间止">
                            <a-input v-model:value="suodeqijianzhi" placeholder="请输入所得期间止"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="收入额">
                            <a-input v-model:value="shourue" placeholder="请输入收入额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="基本养老保险费">
                            <a-input v-model:value="jibenyanglaobaoxianfei" placeholder="请输入基本养老保险费"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="基本医疗保险费">
                            <a-input v-model:value="jibenyiliaobaoxianfei" placeholder="请输入基本医疗保险费"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="失业保险费">
                            <a-input v-model:value="shiyebaoxianfei" placeholder="请输入失业保险费"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="住房公积金">
                            <a-input v-model:value="zhufanggongjijin" placeholder="请输入住房公积金"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="年金">
                            <a-input v-model:value="nianjin" placeholder="请输入年金"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="商业健康保险">
                            <a-input v-model:value="shangyejiankangbaoxian" placeholder="请输入商业健康保险"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="投资抵扣">
                            <a-input v-model:value="touzidikou" placeholder="请输入投资抵扣"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="其他扣除">
                            <a-input v-model:value="qitakouchu" placeholder="请输入其他扣除"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="免税所得">
                            <a-input v-model:value="mianshuisuode" placeholder="请输入免税所得"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="税前扣除项目合计">
                            <a-input v-model:value="shuiqiankouchuxiangmuheji" placeholder="请输入税前扣除项目合计"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="减除费用">
                            <a-input v-model:value="jianchufeiyong" placeholder="请输入减除费用"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="准予扣除的捐赠额">
                            <a-input v-model:value="zhunyukouchudejuanzenge" placeholder="请输入准予扣除的捐赠额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="允许扣除的税费">
                            <a-input v-model:value="runxukouchudeshuifei" placeholder="请输入允许扣除的税费"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="财产原值">
                            <a-input v-model:value="caichanyuanzhi" placeholder="请输入财产原值"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="应缴纳所得额">
                            <a-input v-model:value="yingjiaonasuodee" placeholder="请输入应缴纳所得额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="税率">
                            <a-input v-model:value="shuilv" placeholder="请输入税率"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="速算扣除数">
                            <a-input v-model:value="susuankouchushu" placeholder="请输入速算扣除数"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="应纳税额">
                            <a-input v-model:value="yingnashuie" placeholder="请输入应纳税额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="减免税额">
                            <a-input v-model:value="jianmianshuie" placeholder="请输入减免税额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="应扣缴税额">
                            <a-input v-model:value="yingkoujiaoshuie" placeholder="请输入应扣缴税额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="已扣缴税额">
                            <a-input v-model:value="yikoujiaoshuie" placeholder="请输入已扣缴税额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="应退(补)税额">
                            <a-input v-model:value="yingtuibushuie" placeholder="请输入应退(补)税额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="备注">
                            <a-input v-model:value="beizhu" placeholder="备注"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </a-form>
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" style="margin-right: 20px;" @click="onSave">保存</a-button>
            <a-button type="primary" @click="$router.back()">取消</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'loginType',
            'isAuth',
            'orgId',
            'y'
        ])
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            id: '',
            xuhao: '',
            type: '',
            gonghao: '',
            xingming: '',
            zhengzhaoleixing: '',
            zhengzhaohaoma: '',
            shenbaobiao: '',
            suodexiangmu: '',
            suodeqijianqi: '',
            suodeqijianzhi: '',
            shourue: '',
            jibenyanglaobaoxianfei: '',
            jibenyiliaobaoxianfei: '',
            shiyebaoxianfei: '',
            zhufanggongjijin: '',
            nianjin: '',
            shangyejiankangbaoxian: '',
            touzidikou: '',
            qitakouchu: '',
            mianshuisuode: '',
            shuiqiankouchuxiangmuheji: '',
            jianchufeiyong: '',
            zhunyukouchudejuanzenge: '',
            runxukouchudeshuifei: '',
            caichanyuanzhi: '',
            yingjiaonasuodee: '',
            shuilv: '',
            susuankouchushu: '',
            yingnashuie: '',
            jianmianshuie: '',
            yingkoujiaoshuie: '',
            yikoujiaoshuie: '',
            yingtuibushuie: '',
            beizhu: ''
        }
    },
    methods: {
        // 获取个税数据
        getGSSJDetail () {
            this.$store.dispatch('getGSSJDetail', { INDIVIDUAL_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    console.log(res)
                    let datas = res.pd
                    this.gonghao = datas.GONGHAO
                    this.xingming = datas.UNAME
                    this.zhengzhaoleixing = datas.DOCUTYPE
                    this.zhengzhaohaoma = datas.DOCUCODE
                    this.shenbaobiao = datas.SHENBAOBIAO
                    this.suodexiangmu = datas.SUODEPROJECT
                    this.suodeqijianqi = datas.RECEIVESTART
                    this.suodeqijianzhi = datas.RECEIVEEND
                    this.shourue = datas.RECEIVEMONEY
                    this.jibenyanglaobaoxianfei = datas.JBYLBXF
                    this.jibenyiliaobaoxianfei = datas.JBYANGLAOBXF
                    this.shiyebaoxianfei = datas.SYBXF
                    this.zhufanggongjijin = datas.ZFGJJ
                    this.nianjin = datas.NIANJIN
                    this.shangyejiankangbaoxian = datas.SYJKBX
                    this.touzidikou = datas.TZDK
                    this.qitakouchu = datas.OTHERKOUCHU
                    this.mianshuisuode = datas.MIANSHUISD
                    this.shuiqiankouchuxiangmuheji = datas.SQKCXMHJ
                    this.jianchufeiyong = datas.JIANCHUFY
                    this.zhunyukouchudejuanzenge = datas.ZYKCJZE
                    this.runxukouchudeshuifei = datas.YXKCSF
                    this.caichanyuanzhi = datas.ZCYZ
                    this.yingjiaonasuodee = datas.YJNSDE
                    this.shuilv = datas.SUILV
                    this.susuankouchushu = datas.SSKCS
                    this.yingnashuie = datas.YNSE
                    this.jianmianshuie = datas.JIANMIANSE
                    this.yingkoujiaoshuie = datas.YKJSE
                    this.yikoujiaoshuie = datas.YKJSEA
                    this.yingtuibushuie = datas.YBTSE
                    this.beizhu = datas.REMARK
                } else {
                    this.$message.error('获取销项发票详情失败，请稍后重试！')
                }
            })
        },
        onSave () {
            if (this.type === 'edit') {
                this.onEdit()
            } else if (this.type === 'add') {
                this.onAdd()
            }
        },
        // 添加个税数据
        onAdd () {
            this.$store.dispatch('addGSSJItem', {
                YEAR: this.y,
                ENTERPRISE_ID: this.orgId,
                GONGHAO: this.gonghao,
                UNAME: this.xingming,
                DOCUTYPE: this.zhengzhaoleixing,
                DOCUCODE: this.zhengzhaohaoma,
                SHENBAOBIAO: this.shenbaobiao,
                SUODEPROJECT: this.suodexiangmu,
                RECEIVESTART: this.suodeqijianqi,
                RECEIVEEND: this.suodeqijianzhi,
                RECEIVEMONEY: this.shourue,
                JBYLBXF: this.jibenyanglaobaoxianfei,
                JBYANGLAOBXF: this.jibenyiliaobaoxianfei,
                SYBXF: this.shiyebaoxianfei,
                ZFGJJ: this.zhufanggongjijin,
                NIANJIN: this.nianjin,
                SYJKBX: this.shangyejiankangbaoxian,
                TZDK: this.touzidikou,
                OTHERKOUCHU: this.qitakouchu,
                MIANSHUISD: this.mianshuisuode,
                SQKCXMHJ: this.shuiqiankouchuxiangmuheji,
                JIANCHUFY: this.jianchufeiyong,
                ZYKCJZE: this.zhunyukouchudejuanzenge,
                YXKCSF: this.runxukouchudeshuifei,
                ZCYZ: this.caichanyuanzhi,
                YJNSDE: this.yingjiaonasuodee,
                SUILV: this.shuilv,
                SSKCS: this.susuankouchushu,
                YNSE: this.yingnashuie,
                JIANMIANSE: this.jianmianshuie,
                YKJSE: this.yingkoujiaoshuie,
                YKJSEA: this.yikoujiaoshuie,
                YBTSE: this.yingtuibushuie,
                REMARK: this.beizhu,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('添加成功！')
                    setTimeout(() => {
                        this.$router.push('/ReportForms/GSSJ')
                    }, 1500)
                } else {
                    this.$message.error('添加失败，请稍后重试！')
                }
            })
        },
        // 编辑个税数据
        onEdit () {
            this.$store.dispatch('editGSSJItem', {
                YEAR: this.y,
                ENTERPRISE_ID: this.orgId,
                GONGHAO: this.gonghao,
                UNAME: this.xingming,
                DOCUTYPE: this.zhengzhaoleixing,
                DOCUCODE: this.zhengzhaohaoma,
                SHENBAOBIAO: this.shenbaobiao,
                SUODEPROJECT: this.suodexiangmu,
                RECEIVESTART: this.suodeqijianqi,
                RECEIVEEND: this.suodeqijianzhi,
                RECEIVEMONEY: this.shourue,
                JBYLBXF: this.jibenyanglaobaoxianfei,
                JBYANGLAOBXF: this.jibenyiliaobaoxianfei,
                SYBXF: this.shiyebaoxianfei,
                ZFGJJ: this.zhufanggongjijin,
                NIANJIN: this.nianjin,
                SYJKBX: this.shangyejiankangbaoxian,
                TZDK: this.touzidikou,
                OTHERKOUCHU: this.qitakouchu,
                MIANSHUISD: this.mianshuisuode,
                SQKCXMHJ: this.shuiqiankouchuxiangmuheji,
                JIANCHUFY: this.jianchufeiyong,
                ZYKCJZE: this.zhunyukouchudejuanzenge,
                YXKCSF: this.runxukouchudeshuifei,
                ZCYZ: this.caichanyuanzhi,
                YJNSDE: this.yingjiaonasuodee,
                SUILV: this.shuilv,
                SSKCS: this.susuankouchushu,
                YNSE: this.yingnashuie,
                JIANMIANSE: this.jianmianshuie,
                YKJSE: this.yingkoujiaoshuie,
                YKJSEA: this.yikoujiaoshuie,
                YBTSE: this.yingtuibushuie,
                REMARK: this.beizhu,
                tm: new Date().getTime(),
                INDIVIDUAL_ID: this.id
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('修改成功！')
                    setTimeout(() => {
                        this.$router.push('/ReportForms/GSSJ')
                    }, 1500)
                } else {
                    this.$message.error('修改失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.id = this.$route.query.id
        this.xuhao = this.$route.query.num
        this.type = this.$route.query.type
        if (this.type === 'edit') {
            this.getGSSJDetail()
        }
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }
    }
}
</style>